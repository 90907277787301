<template>
  <table class="b2b-table b2b-table--vstripe b2b-product-compare-table" qaid="productCompareOverview">
    <caption class="sr-only">
      {{
        $t('productCompare.title')
      }}
    </caption>
    <tbody>
      <template v-for="item in sectionData.compareItems">
        <tr
          v-if="showItem(item.code)"
          :key="getRowKey(item.code)"
          class="compare-chart__row"
          :qaid="`productCompareOverview-${item.code}`"
        >
          <th scope="row" :qaid="`productCompareOverviewLabel-${item.code}`">{{ item.label }}</th>
          <td
            v-for="(product, sku) in metaData.products"
            :key="getKey(item.code, sku)"
            class="compare-chart__cell"
            :data-spec="item.label"
            :qaid="`productCompareOverview-${item.code}-${sku}`"
          >
            <compare-model
              v-if="item.code === itemCodes.hero"
              :product="item.products[sku]"
              :sku="`${sku}`"
              :base-properties="baseProperties"
              :show-remove="!meta"
              @remove-product="removeProduct"
            />
            <div v-else-if="hasProductData(item, itemCodes.priceRange, sku)">
              <compare-overview-price :product="item.products[sku]" />
            </div>
            <pdl-swatch-group
              v-else-if="hasItemCode(item, itemCodes.colors)"
              :options="convertColorsToSwatches(item.products[sku])"
              :radio-name="`swatches-${sku}`"
              :is-display-only="true"
              class="justify-start"
            />
            <span v-else-if="hasItemCode(item, itemCodes.powerReviewsID)">
              <a :href="metaData.products[sku].url" class="underline">
                <div :id="`pr-reviewsnippet-${item.products[sku]}`" class="pr-reviewsnippet">
                  <div data-pr-placeholder />
                </div>
              </a>
            </span>
            <span v-else-if="hasProductData(item, itemCodes.projectOneUrl, sku)">
              <a :href="`${item.products[sku]}`" class="underline">{{ bp('yes') }}</a>
            </span>
            <span v-else-if="typeof item.products[sku] === 'string' && !meta">{{ item.products[sku] }}</span>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import {mapState} from 'vuex';
import {PdlSwatchGroup} from '@pedal/pdl-swatch';
import CompareModel from '@/components/compare/CompareModel';
import CompareOverviewPrice from '@/components/compare/CompareOverviewPrice';
import swatchMixin from '@/mixins/swatches.js';

export default {
  components: {
    CompareModel,
    CompareOverviewPrice,
    PdlSwatchGroup,
  },
  mixins: [swatchMixin],
  props: {
    meta: {
      type: Boolean,
      default: false,
    },
    sectionData: {
      type: Object,
      default: () => ({
        url: '',
        name: '',
        useSpecItem: false,
        images: [],
      }),
    },
    sectionKey: {
      type: String,
      required: true,
    },
    metaData: {
      type: Object,
      required: true,
    },
    reviewsRendered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemCodes: {
        hero: 'hero',
        priceRange: 'priceRange',
        colors: 'colors',
        powerReviewsID: 'powerReviewsID',
        projectOneUrl: 'projectOneUrl',
      },
    };
  },
  computed: {
    ...mapState('powerReviews', ['productDetails']),
  },

  watch: {
    sectionData() {
      // Have to make sure the page is fully loaded before attempting to init PowerReviews
      if (document.readyState === 'complete') {
        this.initPowerReviews();
      } else {
        window.addEventListener('load', () => {
          this.initPowerReviews();
        });
      }
    },
  },

  methods: {
    removeProduct(productId) {
      this.$delete(this.metaData.products, productId);
    },

    getKey(code, sku) {
      return `${sku}${code}-${this.meta ? 'meta' : ''}`;
    },

    getRowKey(itemCode) {
      return `${itemCode}-${this.meta ? 'meta' : ''}`;
    },

    showItem(itemCode) {
      return !(this.meta && itemCode !== 'hero');
    },

    hasItemCode(item, itemCode) {
      return item.code === itemCode && !this.meta;
    },

    hasProductData(item, itemCode, sku) {
      return item.code === itemCode && item.products[sku] && !this.meta;
    },

    convertColorsToSwatches(colors) {
      if (!Array.isArray(colors) || colors.length === 0) {
        return [];
      }

      return colors.map((color) => {
        return {
          name: color,
          ...this.processColorCode(color),
        };
      });
    },
    initPowerReviews() {
      if (window.pwr && !this.reviewsRendered) {
        let reviews = [];

        this.sectionData.compareItems.forEach((compareItem) => {
          if (compareItem.code === 'powerReviewsID') {
            for (let product in compareItem.products) {
              let powerReview = {
                locale: this.productDetails.pr_locale,
                merchant_group_id: this.productDetails.pr_merchant_group_id,
                page_id: compareItem.products[product],
                merchant_id: this.productDetails.pr_merchant_id,
                api_key: this.productDetails.pr_api_key,
                review_wrapper_url: '',
                components: {
                  CategorySnippet: 'pr-reviewsnippet-' + compareItem.products[product],
                },
              };

              reviews.push(powerReview);
            }
          }
        });

        if (reviews && reviews.length) {
          window.pwr('render', reviews);
          this.$emit('reviews-rendered');
        }
      }
    },
  },
};
</script>
